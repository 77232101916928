import * as React from 'react'

import { NextPage } from '@grandvisionhq/next'
import { InitialProps, Page, Props } from '@grandvisionhq/www-next/pages/error'

import { layoutSettings, withCms } from '../cms'
import { defaultErrorProps } from '../components/error-props'

const Error: NextPage<Props, InitialProps> = (props) => (
  <Page {...props} {...defaultErrorProps} layoutSettings={layoutSettings} />
)

Error.getInitialProps = Page.getInitialProps

export default withCms({ wrapInContainerPage: false })(Error)
